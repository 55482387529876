<template>
  <b-sidebar
    :id="'add-new-task-sidebar' && expanded ? 'mein-expanded-view' : ' '"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    :no-close-on-backdrop="$refs.refFormObserver && $refs.refFormObserver.flags.dirty"
    no-header
    right
    @hidden="resetForm"
    @change="(val) => { if (!val) $emit('reset-is-editing-to-false'); $emit('update:is-sidebar-active', val) }"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="fixed-sidebar-header d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ title }}
        </h5>
        <div>
          <feather-icon
            v-if="isReadonly"
            class="ml-1 cursor-pointer"
            icon="EditIcon"
            size="16"
            @click="editTab(value)"
          />
          <feather-icon
            :target="`expand-minimize-view`"
            class="ml-1 cursor-pointer"
            :icon="expanded ? 'MinimizeIcon' : 'MaximizeIcon'"
            size="16"
            @click="sideResize"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 add-edit-sidebar-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Task Name"
                rules="required"
              >
                <!-- view name -->
                <b-form-group
                  v-if="isReadonly"
                  label="Task Name"
                >
                  <b-list-group>
                    <b-list-group-item>
                      {{ form.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <!-- form name -->
                <b-form-group
                  v-if="!isReadonly"
                  class="first-required-field"
                >
                  <label
                    for="name"
                    class="label-edit"
                  >Task Name (<span>*</span> <label>required</label> )</label>
                  <b-form-input
                    id="name"
                    v-model="form.name"
                    maxlength="255"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :plaintext="isReadonly"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Description -->
          <b-row>
            <b-col md="12">
              <validation-provider name="Description">
                <label class="label-edit">Description</label>
                <Quill-Editor
                  :description.sync="form.description"
                  :is-readonly.sync="disabled"
                  @assign="changeDesc"
                />
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Process -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Process"
                rules="required"
              >
                <!-- view process-->
                <b-form-group v-if="isReadonly">
                  <label
                    label-for="process"
                    class="label-edit"
                  >
                    Process
                  </label>
                  <b-list-group>
                    <b-list-group-item
                      v-for="option in listGroupItems([form.processId], processOptions)"
                      :key="option.value"
                      :class="option.label === 'Not Assigned' ? 'text-danger' : ''"
                      class="cursor-pointer hyperlink-underlined"
                      @click="gotoProcess(option)"
                    >
                      {{ option.label }}
                      <feather-icon
                        class="ml-1 cursor-pointer"
                        icon="ExternalLinkIcon"
                        size="16"
                      />
                      <b-tooltip
                        title="Open In A New Tab"
                        class="cursor-pointer"
                        :target="`open-new-tab-process-${option.value}`"
                      />
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <!-- form process -->
                <b-form-group
                  v-if="!isReadonly"
                  class="first-required-field"
                  label-for="process"
                  :state="getValidationState(validationContext)"
                >
                  <label
                    for="process"
                    class="label-edit"
                  >Process (<span>*</span> <label>required</label> )</label>
                  <v-select
                    v-model="form.processId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="processOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="isEditing"
                    class="wrapped-li"
                    input-id="process"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Assignee -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Assignee"
                rules="required"
              >
                <!-- view assignee -->
                <b-form-group v-if="isReadonly">
                  <label
                    label-for="assignee"
                    class="label-edit"
                  >
                    Assignee
                  </label>
                  <b-list-group>
                    <b-list-group-item
                      v-for="option in listGroupItems([form.userId], userOptions)"
                      :key="option.value"
                      class="cursor-pointer hyperlink-underlined"
                      :class="option.label === 'Not Assigned' ? 'text-danger' : ''"
                      @click="gotoUser(option)"
                    >
                      {{ option.label }}
                      <feather-icon
                        class="ml-1"
                        icon="ExternalLinkIcon"
                        size="16"
                      />
                      <b-tooltip
                        title="Open In A New Tab"
                        :target="`open-new-tab-assignee-${option.value}`"
                      />
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <!-- form assignee -->
                <b-form-group
                  v-if="!isReadonly"
                  class="first-required-field"
                  label-for="assignee"
                  :state="getValidationState(validationContext)"
                >
                  <label
                    for="assignee"
                    class="label-edit"
                  >Assignee (<span>*</span> <label>required</label> )</label>
                  <v-select
                    v-model="form.userId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="userOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="isReadonly || currentRouteName === 'my-task-list'"
                    class="wrapped-li"
                    input-id="assignee"
                    @input="OnOwnerChange"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Due Date -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Due Date"
                rules="required"
              >
                <!-- due date view -->
                <b-form-group
                  v-if="isReadonly"
                  label="Due Date"
                >
                  <b-list-group>
                    <b-list-group-item>
                      {{ form.dueDate ? formattedDueDate(form.dueDate) : '' }}
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <!-- due date form -->
                <b-form-group
                  v-if="!isReadonly"
                  label="Due Date"
                  label-for="dueDate"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-datepicker
                    id="dueDate"
                    v-model="form.dueDate"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Status -->
          <b-row>
            <b-col :md="expanded ? 6 : 12">
              <validation-provider
                #default="validationContext"
                name="Status"
                rules="required"
              >
                <!-- status view -->
                <b-form-group
                  v-if="isReadonly"
                  label="Status"
                >
                  <b-list-group>
                    <b-list-group-item>
                      {{ splitCamelCaseWords(form.status) }}
                    </b-list-group-item>
                    <b-list-group-item
                      v-if="moment(form.dueDate).diff(Date.now(), 'days') < 0 && form.status !== 'Complete'"
                      class="text-danger"
                    >
                      Late
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>

                <!-- status form -->
                <b-form-group
                  v-if="!isReadonly"
                  label="Status"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="form.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    :disabled="isReadonly"
                    class="wrapped-li"
                    input-id="status"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <!-- Form Actions -->
          <div
            class="mt-2 fixed-footer-form-actions"
            :class="{ 'd-flex': isReadonly || !isEditing }"
          >
            <div v-if="!isReadonly">
              <div
                v-if="isEditing"
                class="d-flex justify-content-between"
              >
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                >
                  Cancel
                </b-button>
                <div>
                  <b-button
                    v-for="el in btns"
                    :key="el"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="el === 'Save' ? 'outline-primary' : 'primary'"
                    :class="{ 'mr-2 ': el === 'Save' }"
                    type="submit"
                    :disabled="isLoading"
                    @click="submitState = el"
                  >
                    {{ el }}
                  </b-button>
                </div>
              </div>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="isLoading"
              >
                Add
              </b-button>
            </div>
            <b-button
              v-if="isReadonly || !isEditing"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormDatepicker, BListGroup, BListGroupItem, BTooltip, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import countries from '@/@fake-db/data/other/countries'
import Enums from '@/utils/enums'
import { formatDate } from '@/utils/date-utils'
import ability from '@/libs/acl/ability'
import QuillEditor from '@/views/shared/QuillEditor.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,
    BListGroup,
    BListGroupItem,
    BTooltip,
    BCol,
    BRow,
    vSelect,
    QuillEditor,
    // BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    isReadonly: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: () => this.getDefaultForm(),
    },
    editTab: {
      type: Function,
      default: () => this.editTab(this.value),
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      form: {},
      statusOptions: Enums.taskStatusOptions,
      btns: ['Save', 'Save & Close'],
      disabled: this.isReadonly,
      expanded: false,
      permanet: false,
      submitState: '',
    }
  },
  computed: {
    ...mapState('app', ['isLoading']),
    ...mapState('task', ['task']),
    ...mapState('process', [
      'processes',
      'allProcesses',
    ]),
    ...mapState('user', [
      'users', 'currentUser', 'allUsers',
    ]),
    isEditing() {
      return !!this.value
    },
    title() {
      if (this.isReadonly) {
        return 'Task Details'
      }
      return this.isEditing ? 'Edit Task' : 'Add New Task'
    },
    userOptions() {
      return this.allUsers.nodes.map(item => ({
        label: item.name,
        value: item.id,
        ...item,
      }))
    },
    processOptions() {
      return this.allProcesses.nodes.map(item => ({
        label: `${item.processHandle}: ${item.name}`,
        value: item.id,
        ...item,
      }))
    },
    currentRouteName() {
      return this.$route.name
    },
  },
  watch: {
    value(value) {
      if (value) {
        this.form = {
          ...value,
          init_user_id: value.userId,
          __typename: undefined,
        }
      }
    },
    isSidebarActive(newValue) {
      if (newValue === true && this.isEditing === false) {
        this.form = this.getDefaultForm()
      }
    },
    isReadonly() {
      this.disabled = this.isReadonly
    },
    task() {
      this.$emit('update:value', { ...this.form, ...this.value, ...this.task })
    },
  },
  mounted() {
    this.refetchProcessesIfNotLoaded()
    if (ability.can('add', 'task')) {
      this.fetchUsersIfNotLoaded()
    }
  },
  methods: {
    ...mapActions('user', [
      'fetchUsersIfNotLoaded',
    ]),
    ...mapActions('process', [
      'refetchProcessesIfNotLoaded',
    ]),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    sideResize() {
      this.expanded = !this.expanded
    },
    changeDesc(value) {
      this.form.description = value
    },
    resolveTaskStatusVariant(status) {
      if (status === 'NotStarted') return 'secondary'
      if (status === 'Complete') return 'success'
      if (status === 'InProgress') return 'info'
      return 'primary'
    },
    formattedDueDate(date) { // This function is used to format the due date displayed in the <b-table>
      if (typeof date === 'string') {
        const year = parseInt(date.split('-')[0], 10)
        const month = parseInt(date.split('-')[1], 10) - 1
        const day = parseInt(date.split('-')[2].split('T')[0], 10)
        return new Date(year, month, day).toDateString()
      }
      return ''

      // <div>{{ new Date(2022, 0, 13).toDateString() }}</div>
    },
    splitCamelCaseWords(text) {
      if (text) {
        const result = text.replace(/([A-Z])/g, ' $1')
        return result.charAt(0).toUpperCase() + result.slice(1)
      }
      return ''
    },
    resetForm() {
      this.permanet = false
      this.$emit('update:value', this.getDefaultForm())
      this.$nextTick(() => {
        this.$refs.refFormObserver.reset()
      })
      this.form = this.getDefaultForm()
    },
    onSubmit() {
      const {
        init_handle, userId, init_user_id,
      } = this.form
      let {
        handle,
      } = this.form
      // eslint-disable-next-line camelcase
      let initHandle = init_handle
      // eslint-disable-next-line camelcase
      const initUserId = init_user_id

      if (!initHandle) {
        if (!initUserId) {
          initHandle = `${this.form.processId}-${userId}-${formatDate(new Date(this.form.dueDate))}`
        } else {
          initHandle = `${this.form.processId}-${initUserId}-${formatDate(new Date(this.form.dueDate))}`
        }
      }
      if (userId !== initUserId) {
        // const index = handle.indexOf('_')
        if (handle.includes('_')) {
          const counterPart = handle.slice(
            handle.lastIndexOf('_'),
          )
          handle = `${this.form.processId}-${userId}-${formatDate(new Date(this.form.dueDate))}${counterPart}`
        } else {
          handle = `${this.form.processId}-${userId}-${formatDate(new Date(this.form.dueDate))}`
        }
      }
      const data = {
        ...this.form,
        // TODO: check the handle substring
        init_handle: initHandle,
        handle: this.isEditing ? handle : `${this.form.processId}-${userId}-${formatDate(new Date(this.form.dueDate))}`,
      }
      delete data.init_user_id
      let action
      if (this.isEditing) {
        const activeRecordCopy = { ...data }
        delete activeRecordCopy.updated_at
        delete activeRecordCopy.process
        delete activeRecordCopy.assignee
        if (activeRecordCopy.id) action = this.$store.dispatch('task/updateTask', activeRecordCopy)
        else action = this.$store.dispatch('task/updateFutureTask', { ...activeRecordCopy, handle })
        action.then(() => {
          if (this.submitState === 'Save') {
            this.$emit('update:is-sidebar-active', true)
            this.$emit('update:is-readonly', true)
          } else {
            this.$emit('update:is-sidebar-active', false)
          }
          this.$emit('regenerate-tasks-to-display')
        })
      } else {
        data.isManuallyCreated = true
        action = this.$store.dispatch('task/addTask', data)
        action.then(() => {
          if (this.submitState === 'Save') {
            this.$emit('update:is-sidebar-active', true)
            this.$emit('update:is-readonly', true)
          } else {
            this.$emit('update:is-sidebar-active', false)
          }
          this.$emit('regenerate-tasks-to-display')
        })
      }

      action
        .then(() => {
          if (this.submitState === 'Save') {
            this.$emit('update:is-sidebar-active', true)
            this.$emit('update:is-readonly', true)
          } else {
            this.$emit('update:is-sidebar-active', false)
          }
          this.$emit('regenerate-tasks-to-display')
          if (this.isEditing) {
            this.$emit('reset-is-editing-to-false')
          }
        })
    },
    getDefaultForm() {
      return {
        name: '',
        handle: '',
        description: '',
        processId: null,
        userId: this.currentRouteName === 'my-task-list' ? this.currentUser.id : null,
        dueDate: new Date(),
        status: Enums.taskStatusOptions[0].value,
      }
    },
    OnOwnerChange(data) {
      if (this.value.userId === data) {
        this.permanet = false
      } else {
        this.permanet = true
      }
    },
    listGroupItems(ids, array) {
      if (ids && ids[0] != null && ids.length > 0) {
        const items = ids.map(id => array.find(el => el.value === id))
        return items
      }
      const item = [{ label: 'Not Assigned', value: `${Math.random()}` }]
      return item
    },
    gotoUser(item) {
      const route = this.$router.resolve({ path: '/people', query: { q: `peopleHandle=${item.peopleHandle}`, qd: item.peopleHandle, compact: true } })
      window.open(route.href, '_blank')
    },
    gotoProcess(item) {
      const route = this.$router.resolve({ path: '/processes', query: { q: `processHandle=${item.processHandle}`, qd: item.processHandle, compact: true } })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
#mein-expanded-view {
  width: 80% !important;
}
</style>

<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-select';

#add-new-task-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.form-group {
  label {
    font-size: 1rem;
  }
}

.required-field {
  label::after {
    content: '*';
    color: #ea5455;
    font-weight: bold;
    font-size: larger;
    margin-left: 2px;
  }
}

.first-required-field {
  span {
    color: #ea5455;
    font-weight: bold;
    font-size: larger;
  }
}

.text-area {
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  padding: 14px;
}

.fixed-sidebar-header {
  position: fixed;
  width: 100%;
  z-index: 10000;
}

.add-edit-sidebar-form {
  margin-top: 40px;
  margin-bottom: 52px;
  padding-top: 12px;
}

.fixed-footer-form-actions {
  position: fixed;
  bottom: 0;
  padding: 16px 58px 16px 0;
  width: 100%;
  background-color: white;
  z-index: 10000;
}
</style>
