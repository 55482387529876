import moment from 'moment'

/**
 * handles timezone and formats date to YYYY-MM-DD
 * @param d
 */
// eslint-disable-next-line import/prefer-default-export
export function formatDate(d) {
  // const offset = d.getTimezoneOffset()
  // const date = new Date(d.getTime() - (offset * 60 * 1000))
  return d.toISOString().split('T')[0]
}

export function formatDateForUser(d) {
  // const offset = d.getTimezoneOffset()
  // const date = new Date(d.getTime() - (offset * 60 * 1000))
  return moment(d).format('YYYY-MM-DD')
}
